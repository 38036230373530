<template>
  <v-card class="mb-7">
    <v-data-table :headers="tableColumnHeaders" :items="rewardPointLogs" :loading="loading">
      <!-- points -->
      <template #[`item.points`]="{ item }">
        <span class="font-weight-bold" :class="{ 'text-success': item.points >= 0, 'text-danger': item.points < 0 }">{{ item.points }}</span>
      </template>

      <!-- created at -->
      <template #[`item.created_at`]="{ item }">
        {{ dayjs(item.created_at).format('YYYY-MM-DD HH:mm:ss') }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { getRewardPointLogs } from '@api/user'
import { onMounted, ref } from '@vue/composition-api'
import emitter from '@/eventBus'
import dayjs from 'dayjs'

export default {
  components: {},
  props: {
    userId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const loading = ref(false)
    const rewardPointLogs = ref([])

    const tableColumnHeaders = [
      {
        text: 'Type',
        value: 'type',
        sortable: false,
      },
      {
        text: 'Points',
        value: 'points',
        sortable: false,
      },
      {
        text: 'Old Points',
        value: 'old_points',
        sortable: false,
      },
      {
        text: 'New Points',
        value: 'new_points',
        sortable: false,
      },
      {
        text: 'Create Date',
        value: 'created_at',
        sortable: false,
      },
    ]

    const loadRewardPointLogs = async () => {
      try {
        const {
          data: { status, data },
        } = await getRewardPointLogs(props.userId)

        if (status === 'success') {
          rewardPointLogs.value = data.records
        }
      } catch (error) {
        console.error(error.message)
      }
    }

    onMounted(async () => {
      if (props.userId) {
        await loadRewardPointLogs()
      }

      emitter.on('reward-points:updated', e => {
        loadRewardPointLogs()
      })
    })

    return {
      dayjs,

      loading,
      rewardPointLogs,

      tableColumnHeaders,
    }
  },
}
</script>

<style lang="scss" scoped>
.text-success {
  color: green;
}

.text-danger {
  color: red;
}
</style>

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "mb-7"
  }, [_c('v-data-table', {
    attrs: {
      "headers": _vm.tableColumnHeaders,
      "items": _vm.rewardPointLogs,
      "loading": _vm.loading
    },
    scopedSlots: _vm._u([{
      key: "item.points",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('span', {
          staticClass: "font-weight-bold",
          class: {
            'text-success': item.points >= 0,
            'text-danger': item.points < 0
          }
        }, [_vm._v(_vm._s(item.points))])];
      }
    }, {
      key: "item.created_at",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(_vm.dayjs(item.created_at).format('YYYY-MM-DD HH:mm:ss')) + " ")];
      }
    }], null, true)
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
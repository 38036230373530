var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "600px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "color": "primary"
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v(_vm._s(_vm.icons.mdiPlus))]), _c('span', [_vm._v("Add")])], 1)];
      }
    }]),
    model: {
      value: _vm.isDialogVisible,
      callback: function callback($$v) {
        _vm.isDialogVisible = $$v;
      },
      expression: "isDialogVisible"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "headline"
  }, [_vm._v("Add Reward Points")])]), _c('v-card-text', [_c('v-container', [_c('v-form', {
    ref: "form"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.rewardPointTypes,
      "item-text": "name",
      "item-value": "value",
      "hide-details": "auto",
      "label": "Type",
      "rules": [_vm.required]
    },
    model: {
      value: _vm.formData.type,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "type", $$v);
      },
      expression: "formData.type"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Points",
      "persistent-hint": "",
      "hide-details": "auto",
      "rules": [_vm.required, _vm.between(_vm.formData.points, 1, 9999)]
    },
    model: {
      value: _vm.formData.points,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "points", $$v);
      },
      expression: "formData.points"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-menu', {
    attrs: {
      "close-on-content-click": false,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "auto"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on,
          attrs = _ref2.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          attrs: {
            "readonly": "",
            "label": "Expiry Date",
            "hint": "Leave it blank if never expired",
            "persistent-hint": ""
          },
          model: {
            value: _vm.formData.expiry_date,
            callback: function callback($$v) {
              _vm.$set(_vm.formData, "expiry_date", $$v);
            },
            expression: "formData.expiry_date"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }]),
    model: {
      value: _vm.isDatePickerVisible,
      callback: function callback($$v) {
        _vm.isDatePickerVisible = $$v;
      },
      expression: "isDatePickerVisible"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "color": "primary"
    },
    on: {
      "input": function input($event) {
        _vm.isDatePickerVisible = false;
      }
    },
    model: {
      value: _vm.formData.expiry_date,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "expiry_date", $$v);
      },
      expression: "formData.expiry_date"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-switch', {
    attrs: {
      "label": "Notify User"
    },
    model: {
      value: _vm.formData.notify,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "notify", $$v);
      },
      expression: "formData.notify"
    }
  })], 1)], 1)], 1)], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "error",
      "outlined": "",
      "disabled": _vm.loading
    },
    on: {
      "click": function click($event) {
        _vm.isDialogVisible = false;
      }
    }
  }, [_vm._v(" Close ")]), _c('v-btn', {
    attrs: {
      "color": "success",
      "disabled": _vm.loading,
      "loading": _vm.loading
    },
    on: {
      "click": function click($event) {
        return _vm.onSubmit();
      }
    }
  }, [_vm._v(" Confirm ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
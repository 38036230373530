<template>
  <div class="user-tab-reward-points">
    <v-row>
      <v-col cols="12">
        <reward-point-table :user-id="userId"></reward-point-table>
      </v-col>
      <v-col cols="12">
        <p>Transactions</p>
        <reward-point-log-table :user-id="userId"></reward-point-log-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import RewardPointLogTable from './RewardPointLogTable.vue'
import RewardPointTable from './RewardPointTable.vue'

export default {
  components: { RewardPointTable, RewardPointLogTable },
  props: {
    userId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {},
}
</script>

<style lang="scss" scoped></style>

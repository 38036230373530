<template>
  <v-card class="mb-7">
    <v-card-text class="d-flex align-center flex-wrap">
        <v-spacer></v-spacer>

        <add-reward-point-modal :user-id="userId" />
    </v-card-text>
    <v-data-table :headers="tableColumnHeaders" :items="rewardPoints" :loading="loading">
      <!-- Traceable -->
      <template #[`item.traceable`]="{ item }">
        <span class="text-no-wrap">{{ parseTraceableData(item.type, item.traceable) }}</span>
      </template>

      <!-- Expiry date -->
      <template #[`item.expired_at`]="{ item }">
        <span class="text-no-wrap">{{ item.expired_at ? dayjs(item.expired_at).format('YYYY-MM-DD HH:mm:ss') : '-' }}</span>
      </template>

      <!-- action -->
      <template #[`item.actions`]="{ item }">
        <edit-reward-point-modal :resource="item" :user-id="userId" />
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { getRewardPoints } from '@api/user'
import { onMounted, ref } from '@vue/composition-api'
import AddRewardPointModal from './AddRewardPointModal.vue'
import EditRewardPointModal from './EditRewardPointModal.vue'
import emitter from '@/eventBus'
import dayjs from 'dayjs'

export default {
  components: {
    AddRewardPointModal,
    EditRewardPointModal,
  },
  props: {
    userId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const loading = ref(false)
    const rewardPoints = ref([])

    const tableColumnHeaders = [
      {
        text: 'Type',
        value: 'type',
        sortable: false,
      },
      {
        text: 'Points',
        value: 'points',
        sortable: false,
      },
      {
        text: 'Traceable',
        value: 'traceable',
        sortable: false,
      },
      {
        text: 'Expiry Date',
        value: 'expired_at',
        sortable: false,
      },
      {
        text: 'Actions',
        value: 'actions',
        align: 'center',
        sortable: false,
      },
    ]

    const loadRewardPoints = async () => {
      loading.value = true

      try {
        const {
          data: { status, data },
        } = await getRewardPoints(props.userId)

        if (status === 'success') {
          rewardPoints.value = data.records
        }
      } catch (error) {
        console.error(error.message)
      } finally {
        loading.value = false
      }
    }

    onMounted(async () => {
      if (props.userId) {
        await loadRewardPoints()
      }

      emitter.on('reward-points:updated', e => {
        loadRewardPoints()
      })
    })

    const parseTraceableData = (type, data) => {
      if (!data) {
        return '-'
      }

      if (type === 'voucher') {
        return data.code
      }
    }

    return {
      dayjs,

      loading,
      rewardPoints,

      tableColumnHeaders,
      parseTraceableData,
    }
  },
}
</script>

<style lang="scss" scoped></style>

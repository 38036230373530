var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "mb-7"
  }, [_c('v-card-text', {
    staticClass: "d-flex align-center flex-wrap"
  }, [_c('v-spacer'), _c('add-reward-point-modal', {
    attrs: {
      "user-id": _vm.userId
    }
  })], 1), _c('v-data-table', {
    attrs: {
      "headers": _vm.tableColumnHeaders,
      "items": _vm.rewardPoints,
      "loading": _vm.loading
    },
    scopedSlots: _vm._u([{
      key: "item.traceable",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('span', {
          staticClass: "text-no-wrap"
        }, [_vm._v(_vm._s(_vm.parseTraceableData(item.type, item.traceable)))])];
      }
    }, {
      key: "item.expired_at",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('span', {
          staticClass: "text-no-wrap"
        }, [_vm._v(_vm._s(item.expired_at ? _vm.dayjs(item.expired_at).format('YYYY-MM-DD HH:mm:ss') : '-'))])];
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('edit-reward-point-modal', {
          attrs: {
            "resource": item,
            "user-id": _vm.userId
          }
        })];
      }
    }], null, true)
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
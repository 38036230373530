<template>
  <v-dialog
    v-model="isDialogVisible"
    max-width="600px"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        color="primary"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>{{ icons.mdiPlus }}</v-icon>
        <span>Add</span>
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <span class="headline">Add Reward Points</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="form">
            <v-row>
              <v-col
                cols="12"
              >
                <v-select
                  v-model="formData.type"
                  :items="rewardPointTypes"
                  item-text="name"
                  item-value="value"
                  hide-details="auto"
                  label="Type"
                  :rules="[required]"
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="formData.points"
                  label="Points"
                  persistent-hint
                  hide-details="auto"
                  :rules="[required, between(formData.points, 1, 9999)]"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-menu
                  v-model="isDatePickerVisible"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="formData.expiry_date"
                      readonly
                      v-bind="attrs"
                      label="Expiry Date"
                      hint="Leave it blank if never expired"
                      persistent-hint
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="formData.expiry_date"
                    color="primary"
                    @input="isDatePickerVisible = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col
                cols="12"
                sm="6"
              >
                <v-switch
                  v-model="formData.notify"
                  label="Notify User"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          outlined
          :disabled="loading"
          @click="isDialogVisible = false"
        >
          Close
        </v-btn>
        <v-btn
          color="success"
          :disabled="loading"
          :loading="loading"
          @click="onSubmit()"
        >
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { between, required } from '@core/utils/validation'
import { ref } from '@vue/composition-api'

import { addRewardPoint } from '@/api/user'
import { useExtractErrors } from '@/composables'
import emitter from '@/eventBus'
import { mdiPlus } from '@mdi/js'
import dayjs from 'dayjs'

export default {
  props: {
    userId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const loading = ref(false)
    const isDialogVisible = ref(false)
    const isDatePickerVisible = ref(false)

    const initialForm = {
      type: null,
      points: 0,
      expiry_date: null,
      notify: true,
    }

    const form = ref(null)
    const formData = ref({ ...initialForm })

    const rewardPointTypes = [
      {
        name: 'Refund',
        value: 'refund',
      },
      {
        name: 'Employee Benefit',
        value: 'employee_benefit',
      },

      // {
      //   name: 'Generic',
      //   value: 'generic',
      // },
    ]

    const resetForm = () => {
      formData.value = initialForm
    }

    const onSubmit = async () => {
      if (!form.value.validate()) {
        return
      }

      loading.value = true

      try {
        formData.value.points = parseInt(formData.value.points)
        formData.value.expiry_date = formData.value.expiry_date ? dayjs(formData.value.expiry_date).endOf('day').format() : null

        const {
          data: { status },
        } = await addRewardPoint(props.userId, formData.value)

        if (status === 'success') {
          emitter.emit('reward-points:updated')

          resetForm()

          isDialogVisible.value = false
        }
      } catch (error) {
        useExtractErrors(error)
      } finally {
        loading.value = false
      }
    }

    return {
      loading,
      isDialogVisible,
      isDatePickerVisible,

      icons: {
        mdiPlus,
      },

      form,
      formData,
      rewardPointTypes,
      onSubmit,

      // validation rules
      required,
      between,
    }
  },
}
</script>

<style>
</style>

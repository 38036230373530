var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "user-tab-reward-points"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('reward-point-table', {
    attrs: {
      "user-id": _vm.userId
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v("Transactions")]), _c('reward-point-log-table', {
    attrs: {
      "user-id": _vm.userId
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }